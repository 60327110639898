<template>
    <div class="w-full" v-if="itemList.length > 0">
        <el-table :height="windowHeight - 200" :data="data" @cell-click="cellClick">
            <el-table-column label-class-name="text-black text-left" prop="name" label="姓名" sortable width="120" fixed="left" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center text-primary cursor-pointer hover:font-semibold" @click="openForm(scope.row, 'name')">
                        <span class="font-semibold">#{{ scope.row.num }} {{ scope.row.name }}</span>
                    </div>
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.account }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="appt" label="掛號" sortable width="180" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="grid grid-cols-1 place-items-start" v-if="scope.row.appt.length > 0">
                        <span v-for="(item, index) in scope.row.appt" :key="index">#{{ item.queue_id }} {{ item.name }} 醫師</span>
                    </div>
                    <div class="grid grid-cols-1 place-items-start" v-else>
                        <span class="text-danger">尚未掛號</span>
                    </div>
                    <div class="grid grid-cols-1 place-items-start">
                        <span class="text-sm text-primary cursor-pointer hover:font-semibold" @click="makeEventAppt(scope.row)">前往掛號</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="event_pdf" label="文件" width="180" show-overflow-tooltip v-if="pdf">
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span class="text-success cursor-pointer hover:font-semibold" v-if="scope.row.pdf_signed_path" @click="viewSignedDoc(scope.row.pdf_signed_path)">檢視簽名文件</span>
                        <span class="text-primary cursor-pointer hover:font-semibold" v-else @click="signDoc(scope.row)">文件簽名</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column class-name="text-primary" label-class-name="text-black text-left" v-for="(item, index) in itemList" :key="index" :prop="item.item_code" :label="getItemName(item.item_code)" sortable width="180" v-if="true" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center text-primary cursor-pointer hover:font-semibold" v-for="(mtype, index2) in item.mtype" :key="index2" @click="openForm(scope.row, item.item_code)">
                        <span>{{ getMtypeName(item.item_code, mtype.mtype_code) }}：</span>
                        <span>{{ getValue(scope.row, item.item_code, mtype.mtype_code) }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="event_question" label="問卷" sortable width="93" show-overflow-tooltip v-if="question">
                <template v-slot="scope">
                    <div class="flex justify-start items-center text-primary cursor-pointer hover:font-semibold" @click="goToQuestion(scope.row)" v-if="scope.row.event_question != '-'">
                        <span>查看問卷</span>
                    </div>
                    <div class="flex justify-start items-center text-danger cursor-pointer hover:font-semibold" @click="goToQuestion(scope.row)" v-else>
                        <span>填寫問卷</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 輸入量測數據視窗 -->
        <vs-popup :active.sync="formActive" :title="formTitle" @close="closeForm">
            <FreeClinicForm :data="selectedPatient" :itemCode="selectedItemCode" :itemList="itemList" :event_id="event_id" v-if="selectedPatient"></FreeClinicForm>
        </vs-popup>
        <!-- 簽名文件 -->
        <vs-popup fullscreen :active.sync="viewSignedDocPopupActive" title="檢視簽名文件">
            <iframe :src="viewSignedDocUrl" frameborder="0" style="overflow: hidden; height: 100vh; width: 100%" width="100%" />
        </vs-popup>
    </div>
</template>
<script>
import { recordSignature, getEventItemList } from '@/api/user'
import FreeClinicForm from '@/components/FreeClinic/FreeClinicForm.vue'
export default {
    components: {
        FreeClinicForm,
    },
    data() {
        return {
            event: null,
            itemList: [],
            question: null,
            pdf: null,
            formActive: false,
            selectedPatient: null,
            selectedItemCode: '',
            formTitle: '',
            eventitemlist: [],
            print_url: '',
            viewSignedDocPopupActive: false,
            viewSignedDocUrl: '',
        }
    },
    props: {
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
        data: {
            type: Array,
            required: true,
            default: [],
        },
    },
    provide() {
        return {
            itemList: this.itemList,
            getItemName: this.getItemName,
            getMtypeName: this.getMtypeName,
        }
    },
    mounted() {
        this.getEventItemList()
        this.processSignatureReturn()
        this.createBus()
    },
    watch: {
        event_id() {
            this.getEventItemList()
        },
    },
    computed: {
        windowHeight() {
            return window.innerHeight
        },
        windowWidth() {
            return window.innerWidth
        },
        dname() {
            return this.$store.getters.name
        },
    },
    methods: {
        // 取義檢活動量測項目
        getEventItemList() {
            const payload = {
                event_id: this.event_id,
                withEventItemMt: 1,
                withEventQuestion: 1,
                withPartnerPdf: 1,
            }
            getEventItemList(payload).then((res) => {
                this.event = res.data.data.data[0]
                this.itemList = this.event.item_list
                this.question = this.event.event_question
                this.pdf = this.event.partner_pdf
            })
        },
        getItemName(item_code) {
            const item = _.find(this.itemList, (d) => d.item_code === item_code)

            return item ? this.$common.getLangDB(item) : null
        },
        getMtypeName(item_code, mtype_code) {
            const item = _.find(this.itemList, (d) => d.item_code === item_code)
            const mtype = _.find(item.mtype, (d) => d.mtype_code === mtype_code)

            return mtype ? this.$common.getLangDB(mtype) : null
        },
        getValue(data, item_code, mtype_code) {
            if (!data) return '-'
            if (!data[item_code]) return '-'
            if (!data[item_code][mtype_code]) return '-'
            const item = _.find(this.itemList, (d) => d.item_code === item_code)
            const mtype = _.find(item.mtype, (d) => d.mtype_code === mtype_code)
            if (mtype.form_type == 'number') return data[item_code][mtype_code].value
            if (mtype.form_type == 'radio') {
                let value = parseInt(data[item_code][mtype_code].value)
                if (mtype.value_map_his && value < 10) {
                    return mtype.value_map_his[value]
                }
                return mtype.value_map[value]
            }
            if (mtype.form_type == 'checkbox') {
                let array = this.$common.decimalToArray(data[item_code][mtype_code].value)
                array = _.map(array, function (item) {
                    return mtype.value_map[item]
                })
                return _.join(array, ',')
            }
            return '-'
        },
        cellClick(row, column, cell, event) {
            if (column.property == 'appt' || column.property == 'event_pdf') return
            if (column.property == 'event_question') {
                const url = process.env.VUE_APP_API_URL_EVENT + '/freeclinic/' + this.question.code + '/' + row.mid + '/' + row.pid + '?back=1'
                if (localStorage.getItem('device_type') == 2) {
                    window.open(url, '_system')
                } else {
                    location.href = url
                }
            }
        },
        goToQuestion(row) {
            const url = process.env.VUE_APP_API_URL_EVENT + '/freeclinic/' + this.question.code + '/' + row.mid + '/' + row.pid + '?back=1'
            if (localStorage.getItem('device_type') == 2) {
                window.open(url, '_system')
            } else {
                location.href = url
            }
        },
        openForm(row, itemCode) {
            this.selectedPatient = row
            this.selectedItemCode = itemCode
            this.formTitle = '輸入' + row.name + '量測數據'
            this.formActive = true
        },
        closeForm() {
            this.selectedPatient = null
            this.formActive = false
            // this.resetdata()
        },
        makeEventAppt(data) {
            const eventPatient = {
                id: data.event_patient_id,
                pid: data.pid,
                event_id: this.event_id,
            }
            this.$bus.$emit('makeEventAppt', eventPatient)
        },
        // updatemeasuredata() {},
        // 檢視簽名文件
        viewSignedDoc(url) {
            this.viewSignedDocUrl = 'https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file=' + url
            this.viewSignedDocPopupActive = true
        },
        // 文件簽名
        async signDoc(row) {
            const apiSignatureUrl = process.env.VUE_APP_API_URL + '/app/restful_api/signature/getSignatureUrl'
            const returnUrl = process.env.VUE_APP_URL + '/freeclinic'
            // const returnUrl = 'http://localhost:8080/freeclinic'
            const data = {
                pdf_path: this.pdf.pdf_path,
                sign_config: this.pdf.sign_config,
                info_config: this.pdf.info_config,
                return_url: returnUrl,
                return_info: {
                    mid: row.mid,
                    partnerid: this.event.partnerid,
                    enterpriseid: this.event.enterpriseid,
                    partner_pdf_id: this.pdf.id,
                },
                info: {
                    yyyy: this.$moment().year(),
                    yy: this.$moment().year() - 1911,
                    m: this.$moment().month() + 1,
                    d: this.$moment().date(),
                },
            }
            await axios.post(apiSignatureUrl, data).then((res) => {
                const { status, url } = res.data
                if (status == 'OK') {
                    window.location.href = url
                } else {
                    this.$vs.notify({ color: 'danger', title: '連結數位簽名失敗', text: res.data.message, position: 'top-center' })
                }
            })
        },
        // 處理電子簽名的回傳
        processSignatureReturn() {
            console.log('this.$route.query => ', this.$route.query)
            const { status, sign_id, pdf_signed_path, return_info } = this.$route.query
            if (status != 'success') return
            if (!sign_id) return
            if (!pdf_signed_path) return
            this.$router.replace({
                path: this.$route.path,
                query: {},
            })
            const parsed_return_info = return_info ? JSON.parse(return_info) : null
            const data = {
                partner_id: parsed_return_info.partnerid,
                eid: parsed_return_info.enterpriseid,
                partner_pdf_id: parsed_return_info.partner_pdf_id,
                mid: parsed_return_info.mid,
                pdf_signed_path: pdf_signed_path,
                more: return_info,
            }
            recordSignature(data).then((res) => {
                if (res.data.status == 'OK') {
                    this.$vs.notify({ color: 'gray', title: '數位簽名成功', position: 'top-center' })
                    // this.fetchMonitorMemberList()
                    this.getEventMeasureData()
                } else {
                    this.$vs.notify({ color: 'danger', title: '數位簽名失敗', position: 'top-center' })
                }
            })
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('upsertMesasureData', () => {
                this.closeForm()
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('upsertMesasureData')
    },
}
</script>
